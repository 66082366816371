<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Category
        <div class="card-header-actions">
          <a class="card-header-action" href="list-design" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
              <b-form-group id="title-group"
                label="Nama Kategori Design"
                label-for="title"
              >
                <b-form-input id="title"
                  type="text"
                  v-model.lazy.trim="$v.form.title.$model"
                  :state="chkState('title')"
                  aria-describedby="titleFeedback"
                  placeholder="Masukan Nama Kategori"
                  autofocus
                />
                <b-form-invalid-feedback id="titleFeedback">
                 <span v-if="!this.$v.form.title.required">- Nama Kategori Design tidak boleh kosong </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="description-group"
                label="Deskripsi"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model.lazy.trim="$v.form.description.$model"
                  :state="chkState('description')"
                  placeholder="Input deskripsi"
                  aria-describedby="descriptionFeedback"
                  rows="8"
                ></b-form-textarea>
                <b-form-invalid-feedback id="descriptionFeedback">
                  <span v-if="!$v.form.description.required">- Deskripsi tidak boleh kosong </span>
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="image-category-preview" v-if="image_category.length > 0">
                <ul class="image-category-preview-list">
                  <li class="image-category-preview-list-item">
                    <img :src="image_category" alt="">
                  </li>
                  <li>
                    <b-button variant="danger" class="button-delete" @click="deletePreviewCategory"><i class="fa fa-trash"></i></b-button>
                  </li>
                </ul>
              </div>

              <b-form-group id="image-category-group"
                label="Image Category"
                label-for="image-category"
              >
                <b-form-file
                  :plain="true"
                  accept="image/*"
                  aria-describedby="imageCategoryFeedback"
                  v-model.lazy.trim="$v.form.image_category.$model"
                  :state="chkState('image_category')"
                  @change="onImageCategoryChange"
                >
                  <template slot="file-name" slot-scope="{ names }">
                    <b-badge variant="dark">{{ names[0] }}</b-badge>
                  </template>
                </b-form-file>
                <span id="descriptionFeedback" v-if="submitted && !$v.form.image_category.required">- Image Category tidak boleh kosong.</span>
              </b-form-group>

              <div class="image-category-preview" v-if="template_image.length > 0">
                <ul class="image-category-preview-list">
                  <li class="image-category-preview-list-item">
                    <img :src="template_image" alt="">
                  </li>
                  <li>
                    <b-button variant="danger" class="button-delete" @click="deletePreviewTemplate"><i class="fa fa-trash"></i></b-button>
                  </li>
                </ul>
              </div>

              <b-form-group id="template-image-group"
                label="Template Hadiah Emas"
                label-for="template-image"
              >
                <b-form-file
                  :plain="true"
                  accept="image/*"
                  v-model.lazy.trim="$v.form.template_image.$model"
                  :state="chkState('template_image')"
                  aria-describedby="templateFeedback"
                  @change="onTemplateImageChange"
                >
                  <template slot="file-name" slot-scope="{ names }">
                    <b-badge variant="dark">{{ names[0] }}</b-badge>
                  </template>
                </b-form-file>
                <span id="descriptionFeedback" v-if="submitted && !$v.form.template_image.required">- Template Hadiah Emas tidak boleh kosong.</span>
              </b-form-group>

              <b-row v-if="icon.length > 0">
                <b-col cols='12' v-for="(image, index) in  icon" :key="index">
                  <div class="image-category-preview-icon">
                    <ul class="image-category-preview-icon-list">
                      <li class="image-category-preview-icon-list-item">
                        <img :src="image" alt="">
                      </li>
                      <li>
                        <b-button variant="danger" class="button-delete" @click="deletePreviewIcon(index)"><i class="fa fa-trash"></i></b-button>
                      </li>
                    </ul>
                  </div>
                </b-col>
              </b-row>
              <b-form-group id="template-image-group"
                label="Image Icon"
                label-for="template-image"
              >
                <b-form-file
                :plain="true"
                  multiple
                  accept="image/*"
                  v-model.lazy.trim="$v.form.icon.$model"
                  :state="chkState('icon')"
                  aria-describedby="templateFeedback"
                  @change="onIconChange"
                >
                </b-form-file>
                <span id="descriptionFeedback" v-if="submitted && !$v.form.icon.required">- Icon tidak boleh kosong.</span>
                <span id="descriptionFeedback" v-if="submitted && !$v.form.icon.maxArray">- Icon tidak boleh lebih dari 5 gambar</span>
              </b-form-group>
              <b-form-group
                label="Shortcut Text"
              >
                <v-select
                  taggable
                  multiple
                  push-tags
                  v-model="form.shortcut_text"
                ></v-select>
                <span id="descriptionFeedback" v-if="submitted && !$v.form.shortcut_text.required">- Shortcut Text tidak boleh kosong.</span>
                <span id="descriptionFeedback" v-if="submitted && !$v.form.shortcut_text.maxArrayShortcut">- Shortcut Text tidak boleh lebih dari 5 kata</span>
              </b-form-group>
              <b-form-group id="position-group"
                label="Position"
                label-for="amount"
              >
                <b-form-input id="position"
                  type="number"
                  v-model.lazy.trim="$v.form.position.$model"
                  :state="chkState('position')"
                  aria-describedby="positionFeedback"
                  placeholder="0"
                  autocomplete='given-name'
                  autofocus
                />
                <b-form-invalid-feedback id="positionFeedback" >
                  <span v-if="!$v.form.position.required">- Posisi tidak boleh kosong!</span><br>
                  <span v-if="!$v.form.position.numeric">- Posisi harus berupa angka valid.</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="set-as-group"
                label="Set As"
                label-for="set-as"
              >
                <b-form-select id="set-as"
                  :plain="true"
                  v-model="form.set_as"
                  aria-describedby="setAsFeedback"
                  autocomplete='given-name'
                  autofocus
                  :options="[
                    {value: '1', text: 'Publish'},
                    {value: '0', text: 'Unpublish'}
                  ]"
                >
                </b-form-select>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, helpers, numeric } from "vuelidate/lib/validators"
let image_validate = []
const maxArray = (value) => !helpers.req(value) || image_validate.length <= 5
const maxArrayShortcut = (value) => !helpers.req(value) || value.length <= 5

export default {
  name: "store-design",
  data() {
    return {
      form: {
        title: '',
        description: '',
        set_as: '1',
        image_category: [],
        template_image: [],
        position: '',
        icon: [],
        shortcut_text: []
      },
      image_category: '',
      template_image: '',
      icon: [],
      errors: {
        message: [],
        code: '',
        status: [],
        headers: [],
        form: {
          title: [],
          description: [],
          image: [],
          category: [],
          slug: []
        },
      },
      submitted: false,
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required,
      },
      description: {
        required
      },
      image_category: {
        required
      },
      template_image: {
        required
      },
      icon: {
        required,
        maxArray
      },
      position: {
        required,
        numeric
      },
      shortcut_text: {
        required,
        maxArrayShortcut
      }
    }
  },
  methods: {
    onImageCategoryChange(event) {
      this.form.image_category = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => this.image_category = e.target.result
      reader.readAsDataURL(event.target.files[0])
    },
    onTemplateImageChange(event) {
      this.form.template_image = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => this.template_image = e.target.result
      reader.readAsDataURL(event.target.files[0])
    },
    onIconChange(e) {
      const files = e.target.files;
      for (let index = 0; index < files.length; index++) {
        image_validate = [...image_validate, files[index]];
        this.form.icon = [...this.form.icon, files[index]];
        const reader = new FileReader();
        reader.onload = (e) => this.icon.push(e.target.result);
        reader.readAsDataURL(files[index]);
      }
    },
    onSubmit() {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.isLoading = true

        const input =  new FormData();
        input.append('title', this.form.title)
        input.append('description', this.form.description)
        input.append('image_category', this.form.image_category, this.form.image_category.name)
        input.append('image', this.form.template_image, this.form.template_image.name)
        this.form.icon.map((item, index) => {
          input.append('icon['+ index +']', item, item.name)
        })
        this.form.shortcut_text.map((item, index) => {
          input.append('shortcut_text['+ index +']', item)
        })
        input.append('position', this.form.position)
        input.append('status', this.form.set_as)
        this.$http.post(`gift-card`, input)
          .then(() => {
            this.isLoading = false
            this.$router.push("/list-design");
            this.$toasted.success('Design successfully created!')
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$swal.fire(
                  "Failed!",
                  error.response.data.meta.message,
                  "error"
                );
              }
            }
          })
      }
    },
    deletePreviewCategory() {
      this.image_category = ''
      this.form.image_category = []
    },
    deletePreviewTemplate() {
      this.template_image = ''
      this.form.template_image = []
    },
    deletePreviewIcon(index) {
      this.form.icon = image_validate
      for (let i = 0; i < this.form.icon.length; i++) {
        if (i === index) {
          image_validate.splice(i, 1)
          this.form.icon = image_validate
          this.icon.splice(i, 1)
        }

      }
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }

      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.preview {
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  width: 50%;
  height: 50%;
}
.image-preview {
  padding: 20px;
}

.invalid-feedback {
    font-size: 100% !important;
}
.notValidate {
  border: #f86c6b 1px solid;
}
.validate {
  border: green 1px solid;
}
#descriptionFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}

</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
</style>
<style lang="scss" scoped>
.image-category-preview {
  width: 100%;
  max-width: 320px;
  height: auto;
  &-list {
    display: flex;
    list-style: none;
    padding: 0;
  }

  & > ul > li> img {
    width: 100%;
    max-width: 320px;
    height: auto;
  }
}

.image-category-preview-icon {
  width: 100%;
  max-width: 250px;
  height: auto;

  &-list {
    display: flex;
    list-style: none;
    padding: 0;
  }
  & > ul > li> img {
    width: 100%;
    max-width: 320px;
    height: auto;
  }
}

.button-delete {
  font-size: 20px;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
